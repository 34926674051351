import { ACCOUNT_STATUS, ACCOUNT_TYPE, AEM_CONFIG, AEM_CONTENT_URLS, AEM_LOGOUT_PATH, DATE_ACTION, PROD_LOGIN_URL, PROD_LOGOUT_URL, PROTECTED_URLS, QA_LOGIN_URL, QA_LOGOUT_URL } from '../constants/constants';
import { getLoggedInStatus } from "./UserLoggedInDetails";
export const getLatestExpiryLeaseDate = (accounts) => {
    const leaseAccountData = accounts.reduce((accumulator, account) => {
        if (account?.accountTypeName === 'LEASE' &&
            account?.maturityDate &&
            calculateDateIsLessThan180Days(account.maturityDate)) {
            accumulator.push(account);
        }
        return sortAccountsByMaturityDate(accumulator);
    }, []);
    if (leaseAccountData) {
        return leaseAccountData[0];
    }
};
export const calculateDateIsLessThan180Days = (maturityDate) => {
    const currentDate = new Date();
    const expiryDate = new Date(maturityDate);
    const diffTime = expiryDate.getTime() - currentDate.getTime();
    const diffDays = diffTime / (1000 * 3600 * 24);
    return diffDays < 180;
};
export const sortAccountsByMaturityDate = (accounts) => {
    return accounts.sort((a, b) => {
        // @ts-ignore
        return new Date(a.maturityDate) - new Date(b.maturityDate);
    });
};
export const displayDateInHumanReadableFormat = (date) => {
    return date ? new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    }) : "N/A";
};
export const maskAccountNumber = (accountNumber) => {
    return accountNumber.replace(/.(?=.{8})/g, '*');
};
export const convertAmountToUSDFormat = (amount, maximumFractionDigits) => {
    return amount ? amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: maximumFractionDigits,
    }) : "$0.00";
};
export const sumOfAccountBalances = (...amount) => {
    return amount.reduce((acc, val) => acc + val, 0);
};
export const getQuickLinksContent = (className, label, data, vertical, displayNumbers, showModalAlert) => {
    return {
        className: className,
        label: label,
        vertical: vertical,
        prefixIcon: '',
        displayNumbers: displayNumbers,
        data: data,
        showModalAlert: showModalAlert,
    };
};
export const getBellyBandsIcons = (content, key, nestedKey) => {
    let iconInfo = {
        text: '',
        url: '',
        link: '',
        id: '',
        navigationKeyId: '',
        isExtLink: false,
        analytics: '',
    };
    return content?.[key]?.[nestedKey] && Object.values(content[key][nestedKey]).reduce((accumulator, icon) => {
        iconInfo = {
            text: icon.link.value,
            url: AEM_CONFIG.IMAGES_BASE_URL_QA2 + icon.image?.source,
            link: icon.link.url,
            id: icon.id,
            navigationKeyId: icon.link.navigationKeyId,
            isExtLink: icon.link.showSpeedBump ? icon.link.showSpeedBump : false,
            analytics: icon.link.trackingId,
        };
        accumulator.push(iconInfo);
        return accumulator;
    }, []);
};
export const getEnvironment = () => {
    const url = window.location.href;
    const devPattern = /dev/i;
    const localPattern = /localhost/i;
    const testPattern = /((uat)|(qa)|(test))/i;
    return {
        isLocal: localPattern.test(url),
        isDev: devPattern.test(url),
        isTest: testPattern.test(url),
        isProd: !localPattern.test(url) &&
            !devPattern.test(url) &&
            !testPattern.test(url),
    };
};
export const getAEMBackendUrls = () => {
    return getEnvironment().isProd
        ? AEM_CONTENT_URLS.PROD
        : AEM_CONTENT_URLS.NON_PROD;
};
export const trimFirstCharacterIfSlash = (url) => {
    return url.startsWith('/') ? url.slice(1) : url;
};
export const buildProtectedURL = (url) => {
    const isUserLoggedIn = getLoggedInStatus();
    let completeUrl;
    const env = getEnvironment();
    if (url === AEM_LOGOUT_PATH) {
        completeUrl = env.isProd ? PROD_LOGOUT_URL : QA_LOGOUT_URL;
    }
    else if (env.isLocal || isUserLoggedIn) {
        completeUrl = url;
    }
    else if (PROTECTED_URLS.includes(url)) {
        completeUrl = env.isProd ? `${PROD_LOGIN_URL}${trimFirstCharacterIfSlash(url)}` : `${QA_LOGIN_URL}${trimFirstCharacterIfSlash(url)}`;
    }
    else {
        completeUrl = url;
    }
    return completeUrl;
};
const addOrSubstractADaysToDate = (date, days, action = DATE_ACTION.ADD) => {
    const inputDate = new Date(date); // date format: yyyy-mm-dd
    return action === DATE_ACTION.ADD ? inputDate.setDate(inputDate.getDate() + days) : inputDate.setDate(inputDate.getDate() - days);
};
const currentDateShouldBeBetweenDates = (startDate, endDate) => {
    const currentDate = new Date();
    return currentDate >= startDate && currentDate <= endDate;
};
const currentDateIsGreaterThanDate = (date) => {
    const currentDate = new Date();
    return currentDate > date;
};
export const lifeCycleStatus = (account) => {
    const lifeCycleStatus = { isLoanStart: false, isLoanMid: false, isLoanEnd: false, isLeaseStart: false, isLeaseMid: false, isLeaseEnd: false };
    if (account) {
        const { autoAccountStatusName, loanDetail, maturityDate, leaseDetail, remainingPaymentCount, accountTypeName } = account;
        const isActive = autoAccountStatusName === ACCOUNT_STATUS.ACTIVE;
        const isActiveOrExtended = autoAccountStatusName === ACCOUNT_STATUS.ACTIVE || autoAccountStatusName === ACCOUNT_STATUS.EXTENDED;
        if (accountTypeName === ACCOUNT_TYPE.LOAN) {
            const { loanBookingDate } = loanDetail;
            lifeCycleStatus.isLoanStart = isActive && currentDateShouldBeBetweenDates(addOrSubstractADaysToDate(loanBookingDate, 1), addOrSubstractADaysToDate(loanBookingDate, 59));
            lifeCycleStatus.isLoanMid = isActive && currentDateIsGreaterThanDate(addOrSubstractADaysToDate(loanBookingDate, 60)) && !currentDateIsGreaterThanDate(addOrSubstractADaysToDate(maturityDate, 181, DATE_ACTION.SUBSTRACT));
            lifeCycleStatus.isLoanEnd = !isActive || currentDateIsGreaterThanDate(addOrSubstractADaysToDate(maturityDate, 180, DATE_ACTION.SUBSTRACT)) || remainingPaymentCount <= 3;
        }
        else if (accountTypeName === ACCOUNT_TYPE.LEASE) {
            const { leaseStartDate } = leaseDetail;
            lifeCycleStatus.isLeaseStart = isActive && currentDateShouldBeBetweenDates(addOrSubstractADaysToDate(leaseStartDate, 1), addOrSubstractADaysToDate(leaseStartDate, 59));
            lifeCycleStatus.isLeaseMid = isActive && currentDateIsGreaterThanDate(addOrSubstractADaysToDate(leaseStartDate, 60)) && !currentDateIsGreaterThanDate(addOrSubstractADaysToDate(maturityDate, 181, DATE_ACTION.SUBSTRACT));
            lifeCycleStatus.isLeaseEnd = isActiveOrExtended && currentDateIsGreaterThanDate(addOrSubstractADaysToDate(maturityDate, 180, DATE_ACTION.SUBSTRACT));
        }
    }
    return lifeCycleStatus;
};
export const convertDateStringIntoHumanReadableFormat = (date) => {
    if (date) {
        const dateArray = date.split('');
        return dateArray.length === 9 ? `${dateArray[2]}${dateArray[3]}${dateArray[4]} ${dateArray[0]}${dateArray[1]}, ${dateArray[5]}${dateArray[6]}${dateArray[7]}${dateArray[8]}` : '';
    }
    else {
        return '';
    }
};
