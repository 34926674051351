import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { updateConfig } from '@octagon/public-analytics-dps-service';
import { register } from '@octagon/public-analytics-autobind';
import AppView from './views/AppView/AppView';
import AppProviders from './AppProviders';
import { getEnvironment } from './utils/Utils';
const App = () => {
    // updateConfig only required for non-prod
    if (!getEnvironment().isProd) {
        updateConfig({ env: 'qac2' });
    }
    useEffect(() => {
        const nodes = document.querySelectorAll('.app');
        register(nodes[0]);
    }, []);
    return (_jsx("div", { "data-testid": "app", className: "app", children: _jsx(BrowserRouter, { children: _jsx(AppProviders, { children: _jsx(AppView, {}) }) }) }));
};
export default App;
