import { jsx as _jsx } from "react/jsx-runtime";
import { getEnvironment } from "../../utils/Utils";
import { PROD_EXTEND_SESSION_URL, QA_EXTEND_SESSION_URL } from "../../constants/constants";
import { getLoggedInStatus } from "../../utils/UserLoggedInDetails";
const ExtendSession = ({ location }) => {
    const { isProd } = getEnvironment();
    const sourcePath = isProd ? PROD_EXTEND_SESSION_URL : QA_EXTEND_SESSION_URL;
    const isLoggedIn = getLoggedInStatus();
    return (isLoggedIn && _jsx("img", { src: sourcePath, alt: "", id: location }, location));
};
export default ExtendSession;
